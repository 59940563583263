import { ref, computed, watch } from "@vue/composition-api";
import moment from "moment-timezone";
import store from "@/store";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);
  store.dispatch("tenant/getAll");
  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantFilter = ref(null);
  const tableColumns = [
    { label: "actions.name", key: "actions" },
    { label: "name", key: "name", sortable: true },
    { label: "tenants.title", key: "tenant.name" },
    { label: "alarm_views.fields.alarm_status.label", key: "alarm_status",
      formatter: (value, key, item) => {
        if (value) return value.join(', ')
        return ""
      },
    },
    { label: "alarm_views.is_default", key: "is_default" },
    {
      label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned', sortable: true
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned', sortable: true
    },
  ];
  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  watch([ tenantFilter, currentPage, perPage ], () => {
    refetchData();
  });
  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });
  const fetchList = (ctx, callback) => {
    store
      .dispatch("alarm_views/getAlarmViews", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        tenant: tenantFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };
  const refetchData = () => {
    refListTable.value.refresh();
  };
  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    refetchData,
    tenantOptions,
    tenantFilter,
  };
}
