<template>
  <div>
    <!-- Breadcrumbs -->
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast" />

    <!-- Filters -->
    <filters
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      @itemFilter="addItemFilter"
    />

    <b-card class="m-2" no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>
          <b-col cols="12" md="5">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                variant="secondary"
                to="alarm-views/config-view"
                v-if="$can('create', 'AlarmViews')"
              >
                <span class="text-nowrap text-capitalize">{{
                  $t("actions.add") + " alarm view"
                }}</span>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
                <span class="text-nowrap text-capitalize"> </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label) }}</span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="`/alarm-views/config-view/${data.item.id}`"
              v-if="$can('edit', 'AlarmViews')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.edit")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="confirmDelete(data.item.id)"
              v-if="$can('delete', 'AlarmViews')"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.delete")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BTable,
  BPagination,
} from "bootstrap-vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import vSelect from "vue-select";
import Filters from "./Filters.vue";
import useList from "./useList";
import Swal from "sweetalert2";
import axios from "@axios";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { computed, ref } from "@vue/composition-api";
import store from "@/store";
import Pagination from "@/components/Pagination.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";

export default {
  components: {
    Filters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BTable,
    BPagination,
    Breadcrumb,
    Pagination,
    ToastificationLoadingContent,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start export
    const loadingToast = ref(null);
    const action = ref("list");
    const { t } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };
    const exportExcel = () => {
      action.value == "export";
      buttonExport.value = true;
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
      store
        .dispatch("alarm_views/export", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          id_tenant: tenantFilter.value,
          action: "export",
        })
        .then(() => {})
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans["messages.error.server_error"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadingToast.value.close();
          buttonExport.value = false;
        });
    };
    const buttonExport = ref(false);
    // end export
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
    } = useList();

    const toast = useToast();

    // start breadcrumb
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };

    const nameNav = ref("breadcrumbs.alarms.alarm_views");
    const notifications = computed(() => store.getters["grid/getAlarms"]);
    // end breadcrumb

    const confirmDelete = (id) => {
      Swal.fire({
        title: "¿Estás seguro de elimar la vista?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.delete(`v1/services/views/${id}`).then(() => {
            refetchData();
            toast({
              component: ToastificationContent,
              props: {
                title: "Vista eliminada correctamente",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          });
        }
      });
    };

    return {
      loadingToast,
      action,
      exportExcel,
      buttonExport,
      addItemFilter,
      itemFilter,
      searchQuery,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      notifications,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      confirmDelete,
      nameNav,
      addItemFilterSearch,
      update,
    };
  },
};
</script>