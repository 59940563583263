<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $t("filter") }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2">
          <label>Tenant</label>
          <v-select
            multiple
            v-model="filterItems"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:tenantFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import { String } from "lodash/_freeGlobal";
import vSelect from "vue-select";
import store from "@/store";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array | Number | String,
      default: null,
    },
  },
  data() {
    return { filterItems: [], datafilter: [] };
  },
  // start breadcrumbs
  methods: {
    sendItemFilter() {
      this.datafilter = [];
      this.datafilter.push(this.filterItems);

      this.$emit("itemFilter", this.datafilter);
    },
    returnItemFilter() {
      this.getItemsFilter.forEach((element) => {
        if (this.$route.path == element.to && element.itemFilter!=null) {

            this.filterItems = element.itemFilter[0];


        }
      });
    },
  },
  computed: {
    getItemsFilter() {
      return store.getters["breadcrumbNav/getItemsNav"];
    },
  },
  watch: {
    filterItems: function () {
      this.sendItemFilter();
    },
  },
  created() {
    this.returnItemFilter();
    if (this.filterItems != undefined) {
      this.$emit("update:tenantFilter", this.filterItems);
    }
  },
   // end breadcrumbs
};
</script>